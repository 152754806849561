<span class="country-icon" [style.width]="width + 'px'" [style.height]="height + 'px'">
  <img
    class="country-icon__image"
    [mmTooltip]="countryTitle"
    [mmTooltipDisabled]="isTooltipDisabled"
    mmTooltipPlacement="top"
    mmTooltipColor="white"
    [src]="countryFlagUrl"
    alt="country flag"
  />
  <ng-template #countryTitle>
    <p class="country-icon__tooltip-text">
      {{ countryCode | countryName: (countriesEntity$ | async) }}
    </p>
  </ng-template>
</span>
